const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  ccDropdownOptions: {
    community: [],
    country: [],
  },
  ccDropdownValues: {
    community: [],
    country: {},
  },
  ccDetails: {},
  ccLetters: {},
  ccTranslationData: [],
  historyDetails: [],
  skipTranslation: false,
  initialTranslation: "",
  isTypedText: false,
  isTypedChildText: false,
};
const ChristmasCardTranslationReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "christmasCardTranslationTableData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "ccintTransalation":
      let currentValue = [...state["ccTranslationData"]];
      // currentValue[action.payload.propIndex]["translation"] =
      //   action.payload.propValue;
      currentValue[action.payload.propIndex]["interMediateTranslation"] =
        action.payload.propValue;
      return {
        ...state,
        [action.payload.name]: currentValue,
      };
    case "christmasCardTranslationFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "ccTranslationOnClear":
      return {
        ...state,
        ccTranslationData: [],
        ccDetails: {},
        ccLetters: {},
        isTypedText: false,
        isTypedChildText: false,
      };
    default:
      return state;
  }
};
export default ChristmasCardTranslationReducer;
